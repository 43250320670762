import { Box, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Popup } from '../../../../components/Popup';
import { showErrorToast, showSuccessToast } from '../../../../components/Toast/actions';
import { UserField } from '../../../../models';
import { BooleanValue } from '../../../../types/checkbox';
import { SideBarItemType } from '../../../../types/route';
import { convertFileToBase64 } from '../../../../utils/fileHelper';
import { useCallbackPrompt } from '../../../../utils/useCallbackPrompt';
import { ContentWrapper } from '../../components/ContentWrapper';
import { LoadingWrapper } from '../../components/LoadingWrapper';
import { useUsersHook } from '../../store';
import { BLANK_STRING } from '../../types';
import { isCreateUserFieldsForm } from '../AddNew/type';
import { Body } from '../components/BodyEdit';
import { HeaderBar } from '../components/HeaderBar';
import { formatDateTime, TimeFormatType } from '../../../../utils/date';

export const EditUser: React.FunctionComponent = () => {
  const { t } = useTranslation();

  const [{ userInfo, locationList }, { getUserInfo, getFreeTextLabel, editUser, getAllUsers }] = useUsersHook();
  const navigate = useNavigate();
  const { id } = useParams();

  const userId = id || BLANK_STRING;

  const [loading, setLoading] = useState<boolean>(false);

  const { showPrompt, cancelNavigation, confirmNavigation } = useCallbackPrompt();

  const handleSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    const data = Object.fromEntries(new FormData(e.target));

    if (!isCreateUserFieldsForm(data)) return;

    const { avatar, emergency, locationId: locationName, startDate, endDate, tagActive, ...rest } = data;
    const locationId = locationList.find(item => item.location === locationName)?.id;

    const editFields: UserField = {
      ...rest,
      emergency: emergency ? BooleanValue.TRUE : BooleanValue.FALSE,
      avatar: await convertFileToBase64(avatar),
      tagActive: tagActive ? BooleanValue.TRUE : BooleanValue.FALSE,
      locationId: locationId ? `${locationId}` : '',
      startDate: formatDateTime(new Date(startDate), TimeFormatType.LONGDATETIME_V2),
      endDate: formatDateTime(new Date(endDate), TimeFormatType.LONGDATETIME_V2)
    };

    try {
      setLoading(true);
      await editUser(editFields, userId);
      await getAllUsers();

      showSuccessToast({
        title: t('user.edit.message.success.title'),
        subtitle: t('user.edit.message.success.subtitle')
      });

      navigate(`/users/${SideBarItemType.User}/${id}`);

      confirmNavigation();
    } catch (error) {
      showErrorToast({
        title: t('user.edit.message.fail.title'),
        subtitle: t('user.edit.message.fail.subtitle')
      });
    } finally {
      setLoading(false);
    }
  };

  const getUserInformation = async (id: string) => {
    setLoading(true);
    await getUserInfo(id);
    await getFreeTextLabel();
    setLoading(false);
  };

  useEffect(() => {
    !userInfo && getUserInformation(userId);
  }, []);

  return (
    <ContentWrapper>
      <Popup
        title={t('user.edit.popup.title')}
        styleChoice="style2"
        open={showPrompt}
        onClose={cancelNavigation}
        closeactiontext={t('Continue editing user')}
        action={{
          name: t('user.edit.button.quit'),
          onAction: confirmNavigation
        }}
      />

      {loading || !userInfo ? (
        <LoadingWrapper>
          <CircularProgress color="primary" size={40} />
        </LoadingWrapper>
      ) : (
        <Box component="form" onSubmit={handleSubmit} height="98%">
          <HeaderBar
            headerTitle={t('user.edit_user')}
            actionTitle={t('user.save')}
            onClickCancel={() => {
              navigate(-1);
            }}
          />

          <Body isEdit={true} setLoading={setLoading} />
        </Box>
      )}
    </ContentWrapper>
  );
};

const a = {
  name: 'a',
  employeeNr: '',
  department: '',
  phone: '',
  email: 'aaa',
  tagNumber: 'a',
  pin: '',
  tagText: '',
  startDate: '10-09-2024',
  endDate: '10-09-2024',
  freeTextText1: '',
  freeTextText2: '',
  comment: '',
  groupIds: '1',
  emergency: '0',
  avatar: 'data:application/octet-stream;base64,',
  tagActive: '0',
  locationId: '1'
};

const b = {
  name: 'Jan111 jaap22 We111issink2',
  tagNumber: 'FFF22F1111111FFF222F',
  pin: '',
  locationId: '1',
  comment: '',
  roomNumber: '',
  phone: '',
  email: 'Jaap@1122222weis111sink.nl',
  userNumber: '',
  tagActive: '',
  tagText: '',
  freeTextText1: '',
  freeTextText2: '',
  emergency: '',
  visitor: '',
  startDate: '2022-02-01 00:00:00',
  endDate: '2024-10-01 00:00:00',
  employeeNr: '',
  department: ''
};
