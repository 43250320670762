import React, { useRef } from 'react';
import { Box, styled, Tab, Tabs } from '@mui/material';
import LogHistoryReport from './LogHistory';
import { useTranslation } from 'react-i18next';
import { TabPanel } from './components';
import AuthReport from './Auth';
import Dashboard from './Dashboard';
import { theme } from '../../styles/theme';
import { TabIndex } from './types'; // Zorg ervoor dat je TabIndex importeert

const Report: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [tab, setTab] = React.useState<TabIndex>(TabIndex.LOG_HISTORY_REPORT);
  const tableRef = useRef<HTMLDivElement>(null);

  const handleSwitchTab = (_event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  return (
    <Wrapper>
      <Content>
        <TabMenu value={tab} onChange={handleSwitchTab}>
          <ReportTab label={t('report.log_history.content')} />
          <ReportTab label={t('report.auth.content')} />
          <ReportTab label={t('report.administrator.content')} />
          <ReportTab label={t('report.presencelist.content')} />
          <ReportTab label={t('report.presencetime.content')} />
          <ReportTab label={t('report.dashboard_log.content')} /> {/* Nieuwe tab toegevoegd */}
        </TabMenu>
        <TabPanel value={tab} index={TabIndex.LOG_HISTORY_REPORT}>
          <LogHistoryReport />
        </TabPanel>
        <TabPanel value={tab} index={TabIndex.AUTH_REPORT}>
          <AuthReport />
        </TabPanel>
        <TabPanel value={tab} index={TabIndex.ADMINISTRATOR_ACTIVITY_LOG}>
          <AuthReport />
        </TabPanel>
        <TabPanel value={tab} index={TabIndex.PRESENCE_LIST}>
          <AuthReport />
        </TabPanel>
        <TabPanel value={tab} index={TabIndex.PRECENSE_TIME_REPORT}>
          <AuthReport />
        </TabPanel>
        <TabPanel value={tab} index={TabIndex.DASHBOARD_LOG}>
          {' '}
          {/* Nieuwe TabPanel toegevoegd */}
          <Dashboard /> {/* Geldige referentie doorgegeven */}
        </TabPanel>
      </Content>
    </Wrapper>
  );
};

export default Report;

const { common, grey } = theme.palette;

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  background-color: ${common.white};
`;

const Content = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const TabMenu = styled(Tabs)`
  width: 98%;
  display: flex;
  align-self: center;
  border-bottom: 1px solid ${grey[400]};
`;

const ReportTab = styled(Tab)`
  font-size: 14px;
  font-weight: 600;
  height: 56px;
  color: ${grey[300]};
`;
