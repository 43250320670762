import React, { useState, useEffect, MouseEvent } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Menu,
  MenuItem,
  Dialog,
  DialogContent,
  IconButton as MuiIconButton,
  Tooltip
} from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import LinkedCameraOutlinedIcon from '@mui/icons-material/LinkedCameraOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { $get } from '../../utils/http'; // Zorg ervoor dat dit pad correct is

interface ANPRCameraBlockProps {
  locationIds?: number[]; // Optioneel, als er geen locatie-ID's zijn opgegeven, worden alle locaties opgehaald
}

interface ANPRData {
  location_name: string;
  camera_name: string;
  time: string; // Formaat: 'DD-MM-YYYY HH:mm:ss' of 'DD-MM-YYYY HH:mm'
  license_plate: string;
  photo: string;
}

const ANPRCameraBlock: React.FC<ANPRCameraBlockProps> = ({ locationIds }) => {
  const [data, setData] = useState<ANPRData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [contextMenu, setContextMenu] = useState<{ mouseX: number; mouseY: number; rowIndex: number | null } | null>(
    null
  );
  const [highlightedRow, setHighlightedRow] = useState<number | null>(null);
  const [openImageDialog, setOpenImageDialog] = useState<boolean>(false);
  const [selectedImageSrc, setSelectedImageSrc] = useState<string | null>(null);

  const navigate = useNavigate();

  // Helper functie om 'DD-MM-YYYY HH:mm:ss' naar Date object te parsen
  const parseDateTime = (dateTimeStr: string): Date | null => {
    // Verwachte formaten: 'DD-MM-YYYY HH:mm:ss' of 'DD-MM-YYYY HH:mm'
    const [datePart, timePart] = dateTimeStr.split(' ');
    if (!datePart || !timePart) return null;
    const [day, month, year] = datePart.split('-').map(Number);
    const [hour, minute, second] = timePart.split(':').map(Number);
    if (isNaN(day) || isNaN(month) || isNaN(year) || isNaN(hour) || isNaN(minute)) return null;
    return new Date(year, month - 1, day, hour, minute, second || 0);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        let url = '/dashboard/anprCameraData';
        if (locationIds && locationIds.length > 0) {
          url += `?locationIds=${locationIds.join(',')}`;
        }

        console.log('Fetching data from:', url);

        const response = await $get(url);
        console.log('Data received:', response);

        // Wrap de response in een array als het een enkel object is
        const dataArray: ANPRData[] = Array.isArray(response) ? response : [response];
        console.log('Data Array:', dataArray);

        // Controleer elke time-waarde en log ongeldige datums
        dataArray.forEach((item, index) => {
          const date = parseDateTime(item.time);
          if (!date) {
            console.warn(`Invalid time at index ${index}: ${item.time}`);
          }
        });

        // Sorteer de data op datum, aflopend (nieuwste eerst) en filter ongeldige datums
        const sortedData = dataArray
          .filter(item => {
            const date = parseDateTime(item.time);
            return date !== null;
          })
          .sort((a, b) => {
            const dateA = parseDateTime(a.time);
            const dateB = parseDateTime(b.time);
            if (!dateA || !dateB) return 0;
            return dateB.getTime() - dateA.getTime();
          });

        setData(sortedData);
        setError(null);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to get data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [locationIds]);

  const handleContextMenu = (event: MouseEvent, rowIndex: number) => {
    event.preventDefault();
    setHighlightedRow(rowIndex);
    setContextMenu({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
      rowIndex: rowIndex
    });
  };

  const handleClose = () => {
    setContextMenu(null);
    setHighlightedRow(null);
  };

  const handleShowLicensePlatePicture = () => {
    if (contextMenu && contextMenu.rowIndex !== null) {
      const { photo } = data[contextMenu.rowIndex];
      setSelectedImageSrc(photo); // Stel de bron van de afbeelding in
      setOpenImageDialog(true); // Open de afbeeldingsdialoog
    }
    handleClose();
  };

  const handleCloseImageDialog = () => {
    setOpenImageDialog(false); // Sluit de afbeeldingsdialoog
  };

  if (loading) {
    return <BlockContainer>Loading...</BlockContainer>;
  }

  if (error) {
    return <BlockContainer>Error: {error}</BlockContainer>;
  }

  return (
    <BlockContainer>
      <Header>
        <TitleContainer>
          <LinkedCameraOutlinedIconStyled />
          <Title>ANPR CAMERA</Title>
        </TitleContainer>
        <HeaderRight>
          <DateText>{new Date().toLocaleDateString('en-GB').replace(/-/g, '/')}</DateText>
        </HeaderRight>
      </Header>
      <TableContainer>
        <StyledTable>
          <TableHead>
            <TableRow>
              <TableHeaderCell>Location</TableHeaderCell>
              <TableHeaderCell>Camera Name</TableHeaderCell>
              <TableHeaderCell>Time</TableHeaderCell>
              <TableHeaderCell>License Plate</TableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              data.map((row, index) => {
                const parsedDate = parseDateTime(row.time);
                return (
                  <StyledTableRow
                    key={index}
                    onContextMenu={event => handleContextMenu(event, index)}
                    highlighted={highlightedRow === index}>
                    <TableCell>{row.location_name}</TableCell>
                    <TableCell>{row.camera_name}</TableCell>
                    <TableCell>
                      {parsedDate ? (
                        parsedDate.toLocaleString('en-GB')
                      ) : (
                        <Tooltip title={`Ongeldige tijd: ${row.time}`}>
                          <span style={{ color: 'red' }}>Invalid time</span>
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell>{row.license_plate}</TableCell>
                  </StyledTableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={4}>No data available</TableCell>
              </TableRow>
            )}
          </TableBody>
        </StyledTable>
      </TableContainer>
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
        PaperProps={{
          style: {
            width: '154px',
            height: '53px',
            padding: '8px 0',
            borderRadius: '4px 0 0 0',
            opacity: 1
          }
        }}>
        <StyledMenuItem onClick={handleShowLicensePlatePicture}>Show Picture</StyledMenuItem>
      </Menu>
      <Dialog open={openImageDialog} onClose={handleCloseImageDialog} maxWidth="sm" fullWidth>
        <DialogContent>
          <DialogHeader>
            <StyledIconButton onClick={handleCloseImageDialog} edge="end" color="inherit">
              <CloseIcon />
            </StyledIconButton>
          </DialogHeader>
          {selectedImageSrc && <img src={selectedImageSrc} alt="License Plate" style={{ width: '100%' }} />}
        </DialogContent>
      </Dialog>
    </BlockContainer>
  );
};

export default ANPRCameraBlock;

// Gestylede Componenten

const BlockContainer = styled(Box)`
  width: 545px;
  height: 292px;
  padding: 0px 16px 16px 24px;
  background: #e5e9eb;
  border: 1px solid #d8dfe0;
  border-radius: 4px 0px 0px 0px;
  display: flex;
  flex-direction: column;
`;

const Header = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px 0;
`;

const HeaderRight = styled(Box)`
  display: flex;
  align-items: center;
`;

const TitleContainer = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const Title = styled(Typography)`
  font-family: Lato;
  font-size: 14px;
  font-weight: 800;
  text-align: left;
`;

const DateText = styled(Typography)`
  font-family: Lato;
  font-size: 13px;
  color: #414548;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
`;

const StyledTable = styled(Table)`
  width: 100%;
`;

const TableHeaderCell = styled(MuiTableCell)`
  font-family: Lato;
  font-size: 14px;
  font-weight: 800;
  text-align: left;
  padding: 8px;
  background-color: white;
`;

const StyledTableRow = styled(TableRow)<{ highlighted?: boolean }>`
  background-color: ${({ highlighted }) => (highlighted ? '#D1EC86' : 'inherit')};
  &:nth-of-type(even) {
    background-color: ${({ highlighted }) => (highlighted ? '#D1EC86' : '#f6f9fa')};
  }
`;

const TableCell = styled(MuiTableCell)`
  flex: 1;
  font-family: Lato;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  padding: 8px;
`;

const StyledMenuItem = styled(MenuItem)`
  color: black;
  &:hover {
    color: #96c11f;
  }
`;

const DialogHeader = styled(Box)`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 8px;
`;

const StyledIconButton = styled(MuiIconButton)`
  color: black;
`;

const LinkedCameraOutlinedIconStyled = styled(LinkedCameraOutlinedIcon)`
  color: #000000;
  margin-right: 8px; // Deze regel zorgt voor dezelfde afstand als in EmergencyTeamBlock
`;
