// Dashboard.tsx
import React, { useState, useEffect } from 'react';
import GridLayout, { Layout } from 'react-grid-layout';
import {
  Box,
  IconButton,
  styled,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  Select,
  MenuItem,
  SelectChangeEvent,
  Checkbox,
  ListItemText,
  Chip,
  FormControl,
  InputLabel
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import BlockSelector from './BlockSelector';
import SystemUpdatesBlock from './SystemUpdatesBlock';
import SystemAlertsBlock from './SystemAlertsBlock';
import AtPresentBlock from './AtPresentBlock';
import EmergencyTeamBlock from './EmergencyTeamBlock';
import UnknownBadgesBlock from './UnknownBadgesBlock';
import DoorSpecificSettingsBlock from './DoorSpecificSettingsBlock';
import ExpectedVisitorsBlock from './ExpectedVisitorsBlock';
import ANPRCameraBlock from './EntradaANPRBlock';
import EntradaCamBlock from './EntradaCamBlock';
import LocationSelector from '../../components/LocationSelector'; // Correcte import van LocationSelector
import { Location } from '../../models';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

interface Block {
  id: number;
  type: string;
}

const initialBlocks: Block[] = [
  { id: 1722441408699, type: 'System Alerts' },
  { id: 1722441404402, type: 'Emergency Team' },
  { id: 1722441399698, type: 'At Present' },
  { id: 1722441337706, type: 'Door Specific Settings' },
  { id: 1722441325738, type: 'Expected Visitors' },
  { id: 1722441328162, type: 'Unknown Badges' },
  { id: 1722441344776, type: 'System Updates' },
  { id: 1723756066200, type: 'ANPR Camera' },
  { id: 1723754932170, type: 'Live Cam' }
];

const initialLayout: Layout[] = [
  { w: 5, h: 3, x: 0, y: 0, i: '1722441408699', moved: false, static: true },
  { w: 4, h: 3, x: 9, y: 0, i: '1722441404402', moved: false, static: true },
  { w: 4, h: 3, x: 5, y: 0, i: '1722441399698', moved: false, static: true },
  { w: 5, h: 12, x: 0, y: 3, i: '1722441337706', moved: false, static: true },
  { w: 4, h: 12, x: 5, y: 3, i: '1722441325738', moved: false, static: true },
  { w: 4, h: 8, x: 9, y: 3, i: '1722441328162', moved: false, static: true },
  { w: 4, h: 4, x: 9, y: 11, i: '1722441344776', moved: false, static: true },
  { w: 5, h: 8, x: 13, y: 0, i: '1723756066200', moved: false, static: true },
  { w: 5, h: 10, x: 13, y: 8, i: '1723754932170', moved: false, static: true }
];

const determineBlockSize = (type: string) => {
  switch (type) {
    case 'System Updates':
      return { w: 4, h: 4 };
    case 'System Alerts':
      return { w: 5, h: 3 };
    case 'At Present':
      return { w: 4, h: 3 };
    case 'Emergency Team':
      return { w: 4, h: 3 };
    case 'Unknown Badges':
      return { w: 5, h: 12 };
    case 'Door Specific Settings':
      return { w: 4, h: 12 };
    case 'Expected Visitors':
      return { w: 4, h: 8 };
    case 'ANPR Camera':
      return { w: 5, h: 8 };
    case 'Live Cam':
      return { w: 5, h: 10 };
    default:
      return { w: 6, h: 4 };
  }
};

const Dashboard: React.FC = () => {
  const [blocks, setBlocks] = useState<Block[]>(initialBlocks);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedLocations, setSelectedLocations] = useState<Location[]>([]);
  const [secondarySelection, setSecondarySelection] = useState<string[]>([]); // Multi-select state
  const [layout, setLayout] = useState<Layout[]>(() => {
    const savedLayout = localStorage.getItem('dashboardLayout');
    return savedLayout ? JSON.parse(savedLayout) : initialLayout;
  });
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);

  useEffect(() => {
    const savedBlocks = localStorage.getItem('dashboardBlocks');
    if (savedBlocks) {
      setBlocks(JSON.parse(savedBlocks));
    }
  }, []);

  useEffect(() => {
    if (!isEditing) {
      const savedLayout = localStorage.getItem('dashboardLayout');
      setLayout(savedLayout ? JSON.parse(savedLayout) : initialLayout);
    }
  }, [isEditing]);

  useEffect(() => {
    const newLayout = blocks.map(block => {
      const existingLayout = layout.find(l => l.i === block.id.toString());
      return existingLayout
        ? { ...existingLayout, static: !isEditing }
        : {
            i: block.id.toString(),
            x: 0,
            y: 0,
            w: determineBlockSize(block.type).w,
            h: determineBlockSize(block.type).h,
            static: !isEditing
          };
    });
    setLayout(newLayout);
  }, [blocks, isEditing]);

  const handleAddBlock = (type: string) => {
    const newBlock = { id: Date.now(), type };
    setBlocks(prevBlocks => [...prevBlocks, newBlock]);
  };

  const removeBlock = (id: number) => {
    setBlocks(blocks.filter(block => block.id !== id));
  };

  const handleLayoutChange = (newLayout: Layout[]) => {
    if (isEditing && JSON.stringify(newLayout) !== JSON.stringify(layout)) {
      setLayout(newLayout);
    }
  };

  const handleSecondarySelectionChange = (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value as string[]; // Cast naar string[]
    setSecondarySelection(value);
  };

  const renderBlock = (type: string) => {
    const locationIds = selectedLocations.map(location => location.id);
    switch (type) {
      case 'System Updates':
        return <SystemUpdatesBlock />;
      case 'System Alerts':
        return <SystemAlertsBlock locationIds={locationIds} />;
      case 'At Present':
        return <AtPresentBlock locationIds={locationIds} />;
      case 'Emergency Team':
        return <EmergencyTeamBlock locationIds={locationIds} />;
      case 'Unknown Badges':
        return <UnknownBadgesBlock locationIds={locationIds} />;
      case 'Door Specific Settings':
        return <DoorSpecificSettingsBlock />;
      case 'Expected Visitors':
        return <ExpectedVisitorsBlock locationIds={locationIds} />;
      case 'ANPR Camera':
        return <ANPRCameraBlock locationIds={locationIds} />;
      case 'Live Cam':
        return <EntradaCamBlock locationIds={locationIds} />;
      default:
        return <div>Unknown Widget</div>;
    }
  };

  const toggleFilterDialog = () => {
    setFilterDialogOpen(!filterDialogOpen);
  };

  const tooltipContent =
    selectedLocations.map(location => location.location).join(', ') || 'Geen locaties geselecteerd';

  return (
    <Wrapper>
      <TopBar>
        <Tooltip title={tooltipContent}>
          <FilterButton
            onClick={toggleFilterDialog}
            $active={selectedLocations.length > 0} // Gebruik $active in plaats van active als een styled-prop
          >
            <FilterListIcon />
            {selectedLocations.length > 0 && <ActiveIndicator />} {/* Klein groen cirkeltje */}
          </FilterButton>
        </Tooltip>
      </TopBar>

      <Dialog open={filterDialogOpen} onClose={toggleFilterDialog}>
        <DialogTitle>Locatie Filter</DialogTitle>
        <DialogContent>
          <br></br>
          <StyledLocationSelector selectedLocations={selectedLocations} onLocationChange={setSelectedLocations} />
          <FormControl fullWidth margin="normal" variant="outlined">
            <InputLabel shrink={false} style={{ transform: 'translate(14px, 14px) scale(1)' }}>
              Select Section
            </InputLabel>
            <SecondaryDropdown
              multiple
              value={secondarySelection}
              onChange={handleSecondarySelectionChange}
              disabled={selectedLocations.length === 0} // Disabled wanneer er geen locaties zijn geselecteerd
              displayEmpty
              renderValue={selected => {
                const selectedValues = selected as string[]; // Typecasting naar string[]
                if (selectedValues.length === 0) {
                  return <em></em>;
                }
                return (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selectedValues.map((value: string) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                );
              }}>
              <MenuItem value="" disabled>
                <em>Select Section</em>
              </MenuItem>
              <MenuItem value="optie1">
                <Checkbox checked={secondarySelection.includes('optie1')} />
                <ListItemText primary="Optie 1" />
              </MenuItem>
              <MenuItem value="optie2">
                <Checkbox checked={secondarySelection.includes('optie2')} />
                <ListItemText primary="Optie 2" />
              </MenuItem>
              <MenuItem value="optie3">
                <Checkbox checked={secondarySelection.includes('optie3')} />
                <ListItemText primary="Optie 3" />
              </MenuItem>
            </SecondaryDropdown>
          </FormControl>
        </DialogContent>
      </Dialog>

      <DashboardContent>
        <GridLayout
          className="layout"
          layout={layout}
          cols={24}
          rowHeight={30}
          width={2400}
          onLayoutChange={handleLayoutChange}
          isDraggable={isEditing}
          isResizable={false}
          autoSize={false}
          compactType={null}
          margin={[10, 10]}
          containerPadding={[10, 10]}>
          {blocks.map(block => (
            <div key={block.id.toString()} data-grid={layout.find(l => l.i === block.id.toString())}>
              <BlockWrapper>
                {renderBlock(block.type)}
                {isEditing && <RemoveIcon onClick={() => removeBlock(block.id)} />}
              </BlockWrapper>
            </div>
          ))}
        </GridLayout>
      </DashboardContent>

      {isEditing && (
        <PlusButton onClick={() => setDrawerOpen(true)}>
          <AddIcon />
        </PlusButton>
      )}
      <BlockSelector
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onSelect={handleAddBlock}
        addedBlocks={blocks.map(block => block.type)}
      />
    </Wrapper>
  );
};

export default Dashboard;

// Styled Components

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const TopBar = styled(Box)`
  width: 100%;
  background-color: white;
  padding: 16px;
  height: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const FilterButton = styled(IconButton)<{ $active: boolean }>`
  position: relative;
  color: inherit;
  margin-right: 30px;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const ActiveIndicator = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
  width: 9px;
  height: 9px;
  background-color: #4caf50;
  border-radius: 50%;
  border: 1px solid white;
`;

const StyledLocationSelector = styled(LocationSelector)`
  margin-top: 20px;
  min-width: 200px;
`;

const SecondaryDropdown = styled(Select)`
  min-width: 200px;
  background-color: ${({ disabled }) => (disabled ? '#f0f0f0' : 'white')};
  border: ${({ disabled }) => (disabled ? '1px solid #ddd' : '1px solid #ccc')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;

const DashboardContent = styled(Box)`
  width: 100%;
  height: calc(100vh - 100px);
  overflow-y: auto;
  background-color: #f5f5f5;
  padding: 1px;
`;

const BlockWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  position: relative;
`;

const PlusButton = styled(Box)`
  position: fixed;
  bottom: 16px;
  right: 16px;
  color: #ffffff;
  border: 2px solid #698223;
  background-color: #85a917;
  border-radius: 50%;
  min-width: 36px;
  width: 72px;
  height: 72px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  &:hover {
    background-color: #698223;
    color: #ffffff;
  }
`;

const RemoveIcon = styled(DeleteIcon)`
  position: absolute;
  top: 4px;
  right: 4px;
  cursor: pointer;
  color: #dc3545;
  &:hover {
    color: #c82333;
  }
`;
