import { Box, styled, TableContainer, Typography, Zoom } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { dimensions } from '../../../../styles/dimensions';
import { theme } from '../../../../styles/theme';

import { ReactComponent as Activated } from '../../../../assets/image/Users/Activated.svg';

import { BooleanValue } from '../../../../types/checkbox';

import { useUsersStates } from '../../store';
import { BLANK_STRING, EMPTY_VALUE } from '../../types';
import { UserInfoItem } from '../types';
import { LogHistory } from './LogHistory';
import { CustomTable } from '../../../../components/CustomTable';

export const UserInformation: React.FunctionComponent = () => {
  const { t } = useTranslation();

  const { userInfo, freeTextLabel } = useUsersStates();

  if (!userInfo) return <></>;

  const userInfoDetails: UserInfoItem[] = [
    { label: t('user.emergency_team'), value: userInfo.emergency },
    { label: t('user.employee_number'), value: userInfo.employeeNr },
    { label: t('user.client'), value: userInfo.client },
    { label: t('user.location'), value: userInfo.locationName },
    { label: t('user.phone'), value: userInfo.phone },
    { label: t('user.email'), value: userInfo.email },
    { label: t('user.pin'), value: userInfo.pin },
    { label: t('user.tag_text'), value: userInfo.tagText },
    { label: t('user.tag_active'), value: userInfo.tagActive },
    { label: t('user.end_date'), value: userInfo.endDate },
    { label: freeTextLabel.freeForm1, value: userInfo.freeTextText1 },
    { label: freeTextLabel.freeForm2, value: userInfo.freeTextText2 },
    { label: freeTextLabel.comment, value: userInfo.comment }
  ];

  const minimumGroupsToShow = 4;
  const groupsWithPlaceholders = userInfo.groupNames ? [...userInfo.groupNames] : [];

  // Vul de lijst aan met lege objecten tot je ten minste 5 items hebt
  while (groupsWithPlaceholders.length < minimumGroupsToShow) {
    groupsWithPlaceholders.push({
      groupName: '',
      groupID: '',
      groupLocation: ''
    }); // Of een ander geschikt placeholder object
  }

  const headerColumns = [
    {
      dataKey: 'groupName',
      fieldName: t('Groups this user belongs to')
    }
  ];

  const displayValue = (userInfoItem: UserInfoItem) => {
    switch (userInfoItem.label) {
      case t('user.emergency_team'):
        if (userInfoItem.value === BooleanValue.TRUE)
          return (
            <TagWrapper>
              <Activated />
            </TagWrapper>
          );
        else
          return (
            <Typography variant="body1" color="grey.100">
              {EMPTY_VALUE}
            </Typography>
          );

      case t('user.tag_active'):
        if (userInfoItem.value === BooleanValue.TRUE)
          return (
            <TagWrapper>
              <TagContent variant="body1">{t('user.tag_status.activated')}</TagContent>
              <Activated />
            </TagWrapper>
          );
        else
          return (
            <Typography variant="body1" color="grey.100">
              {EMPTY_VALUE}
            </Typography>
          );

      case t('user.pin'):
        if (userInfoItem.value === BLANK_STRING) return <TagContent variant="body1">{EMPTY_VALUE}</TagContent>;
        else
          return (
            <Typography variant="body1" color="grey.100">
              {userInfoItem.value.replace(/./g, '*')}
            </Typography>
          );

      default:
        return (
          <Typography variant="body1" color="grey.100">
            {userInfoItem.value === BLANK_STRING ? EMPTY_VALUE : userInfoItem.value}
          </Typography>
        );
    }
  };

  return (
    <UserInfoWrapper>
      <WrapperItem>
        {userInfoDetails.map((item, index) => (
          <InfoLineWrapper key={index}>
            <Zoom in={true}>
              <Label variant="body1">{item.label}</Label>
            </Zoom>
            <Zoom in={true}>{displayValue(item)}</Zoom>
          </InfoLineWrapper>
        ))}
      </WrapperItem>
      <WrapperGroup>
        <Title variant="body1">{t('user.group.title')}</Title>
        <GroupContainer>
          <CustomTable
            cellMaxWidth="130px"
            columns={[{ dataKey: 'groupName', fieldName: t('user.group.tabletitle') }]}
            dataList={groupsWithPlaceholders}
          />
        </GroupContainer>
        <LogHistory />
      </WrapperGroup>
    </UserInfoWrapper>
  );
};

const { grey } = theme.palette;
const { base, baseXS, baseLG, baseMD, baseSM } = dimensions;

const Title = styled(Typography)`
  color: ${grey[100]};
  margin: ${base} 0;
`;

const UserInfoWrapper = styled(Box)`
  display: flex;
  width: calc(100% - 48px);
  padding: 0 ${baseLG};
  column-gap: ${baseLG};
  height: calc(100% - 180px);
  border-top: 1px solid ${grey[400]};
  flex-direction: row;
  ${props => props.theme.breakpoints.down(1400)} {
    flex-direction: column;
  }
`;

const WrapperItem = styled(Box)`
  display: flex;
  flex: 1.2;
  flex-direction: column;
  min-height: 500px;
  border-right: 1px solid ${grey[400]};
  row-gap: ${baseSM};
  padding-top: ${baseMD};
  overflow-y: auto;
  ${props => props.theme.breakpoints.down(1400)} {
    overflow-y: visible;
    margin-bottom: 250px;
  }
`;

const InfoLineWrapper = styled(Box)`
  border-bottom: 1px solid ${grey[400]};
  display: flex;
  flex-direction: row;
  padding-bottom: ${baseSM};
  margin-bottom: ${baseXS};
`;

const Label = styled(Typography)`
  min-width: 160px;
  color: ${grey[200]};
`;

const TagWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
`;

const TagContent = styled(Typography)`
  color: ${grey[100]};
  margin-right: ${base};
`;

const WrapperGroup = styled(Box)`
  flex: 1.5;
  min-height: 500px;
  overflow-y: auto;
  padding-bottom: ${base};
`;

const GroupContainer = styled(TableContainer)`
  height: calc((100% - 95px) / 2);
  overflow-y: auto;
`;

const TitleGroup = styled(Typography)`
  color: ${grey[100]};
  margin: ${base} 0;
`;
