import React from 'react';
import { Autocomplete, Box, Chip, Checkbox, MenuItem, TextField, Typography } from '@mui/material';
import { Location } from '../../../models';
import { theme } from '../../../styles/theme';
import { useTranslation } from 'react-i18next';

type LocationFilterProps = {
  locations: Location[];
  selectedLocations: Location[];
  onChange: (locations: Location[]) => void;
};

const LocationFilter: React.FunctionComponent<LocationFilterProps> = ({ locations, selectedLocations, onChange }) => {
  const { t } = useTranslation();

  const handleTagsRender = (tagValues: Location[], getTagProps: any) =>
    tagValues.map((option, index) => (
      <Chip
        key={option.id}
        label={option.location}
        {...getTagProps({ index })}
        sx={index === 0 ? { width: '240px' } : { width: '120px' }}
      />
    ));

  const handleOptionRender = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: Location,
    { selected }: { selected: boolean }
  ) => (
    <MenuItem key={option.id} value={option.id} {...props}>
      <Checkbox checked={selected} />
      <Typography variant="body1" color={selected ? theme.palette.primary.main : theme.palette.common.black}>
        {option.location}
      </Typography>
    </MenuItem>
  );

  return (
    <Autocomplete
      size="small"
      multiple
      freeSolo={false}
      limitTags={1}
      options={locations}
      disableCloseOnSelect
      getLimitTagsText={more => (
        <Chip label={t('dashboard.select_locations.has_more', { number: more })} sx={{ width: '120px' }} />
      )}
      getOptionLabel={(option: Location) => option.location}
      value={selectedLocations}
      onChange={(_e, newLocations) => onChange(newLocations as Location[])}
      renderTags={(tagValues: Location[], getTagProps) => handleTagsRender(tagValues, getTagProps)}
      renderOption={(props, option: Location, { selected }) => handleOptionRender(props, option, { selected })}
      renderInput={params => (
        <TextField
          {...params}
          label={t('dashboard.select_locations.content')}
          sx={{ width: 350, marginLeft: '16px' }}
        />
      )}
      sx={{ caretColor: theme.palette.common.white, marginLeft: '16px' }}
    />
  );
};

export default LocationFilter;
