// PresentTeam.tsx

import React, { useState } from 'react';
import { Box, styled, TableContainer, Typography, Switch, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CustomTable, Column } from '../../components/CustomTable'; // Zorg ervoor dat het pad klopt
import { Location } from '../../models';
import { EmergencyInfo } from '../../models/emergency';
import { theme } from '../../styles/theme';
import { DEFAULT_ID, EmergencyKeys, StatusEmergencyTeam } from './type';
import { CircularLoading } from '../Users/Group/components';
import { combineMemberInMultipleLocation } from './utils';
import LocationFilter from './components/LocationFilter';
import ClearIcon from '@mui/icons-material/Clear';
import { demoEmergencyMembers } from './demo'; // Zorg ervoor dat het pad klopt

const PresentTeam: React.FunctionComponent = () => {
  const { t } = useTranslation();

  // Definieer demo-locaties
  const [locationList] = useState<Location[]>([
    { location: 'Location 1', id: 1, minEmergencyTeam: 0, emergencyTeamPersons: 0 },
    { location: 'Location 2', id: 2, minEmergencyTeam: 0, emergencyTeamPersons: 0 },
    { location: 'Location 3', id: 3, minEmergencyTeam: 0, emergencyTeamPersons: 0 },
    { location: 'Location 4', id: 4, minEmergencyTeam: 0, emergencyTeamPersons: 0 },
    { location: 'Location 5', id: 5, minEmergencyTeam: 0, emergencyTeamPersons: 0 },
    { location: 'Location 6', id: 6, minEmergencyTeam: 0, emergencyTeamPersons: 0 },
    { location: 'Location 7', id: 7, minEmergencyTeam: 0, emergencyTeamPersons: 0 },
    { location: 'Location 8', id: 8, minEmergencyTeam: 0, emergencyTeamPersons: 0 },
    { location: 'Location 9', id: 9, minEmergencyTeam: 0, emergencyTeamPersons: 0 },
    { location: 'Location 10', id: 10, minEmergencyTeam: 0, emergencyTeamPersons: 0 }
  ]);

  const [loading] = useState<boolean>(false); // Geen loading nodig
  const [selectedLocations, setSelectedLocations] = useState<Location[]>([]);
  const [emergencyMembers, setEmergencyMembers] = useState<EmergencyInfo[]>(demoEmergencyMembers);
  const [showAllMembers, setShowAllMembers] = useState<boolean>(false); // Switch standaard uit

  const columns: Column[] = [
    {
      dataKey: EmergencyKeys.NAME,
      fieldName: t('emergency_team.name')
    },
    {
      dataKey: EmergencyKeys.LOCATION,
      fieldName: t('emergency_team.registered_at_location')
    },
    {
      dataKey: EmergencyKeys.LAST,
      fieldName: t('emergency_team.last_scanned_door')
    }
  ];

  const sortListByStatus = (emergencyMembers: EmergencyInfo[]) => {
    const availableTeam = emergencyMembers.filter(team =>
      showAllMembers ? true : team.status === StatusEmergencyTeam.ON
    );
    return availableTeam;
  };

  const handleLocationChange = (locations: Location[]) => {
    setSelectedLocations(locations);
    if (locations.length === 0 || locations.some(location => location.id === DEFAULT_ID)) {
      setEmergencyMembers(combineMemberInMultipleLocation(demoEmergencyMembers));
    } else {
      const filteredMembers = demoEmergencyMembers.filter(member =>
        locations.some(location => location.location === member.location)
      );
      setEmergencyMembers(filteredMembers);
    }
  };

  const handleClearFilters = () => {
    setSelectedLocations([]); // Reset de geselecteerde locaties
    setShowAllMembers(false); // Reset de 'Show All Members' schakelaar
    setEmergencyMembers(combineMemberInMultipleLocation(demoEmergencyMembers)); // Toon alle noodteamleden
  };

  const isFilterApplied = selectedLocations.length > 0 || showAllMembers;

  return (
    <Wrapper>
      {loading ? (
        <CircularLoading />
      ) : (
        <>
          <HeaderWrapper>
            <Typography variant="subtitle4" color="grey.100">
              {t('emergency_team.location')}
            </Typography>

            <LocationFilter
              locations={locationList}
              selectedLocations={selectedLocations}
              onChange={handleLocationChange}
            />

            <SwitchWrapper>
              <CustomSwitch checked={showAllMembers} onChange={() => setShowAllMembers(!showAllMembers)} />
              <CustomTypography variant="body2">{t('emergency_team.all_members')}</CustomTypography>
            </SwitchWrapper>

            {isFilterApplied && (
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClearFilters}
                startIcon={<ClearIcon />}
                sx={{ marginLeft: '16px', marginRight: 'auto' }}>
                {t('door.clear_filters')}
              </Button>
            )}
          </HeaderWrapper>
          <EmergencyTeamContainer>
            <CustomTable columns={columns} dataList={sortListByStatus(emergencyMembers)} />
          </EmergencyTeamContainer>
        </>
      )}
    </Wrapper>
  );
};

export default PresentTeam;

// Gestylede Componenten

const { common, grey } = theme.palette;

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: ${common.white};
`;

const HeaderWrapper = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: ${theme.spacing(3)} ${theme.spacing(3)} 0;
  width: 100%;
`;

const EmergencyTeamContainer = styled(TableContainer)`
  flex-grow: 1;
  overflow-y: auto;
  margin-top: ${theme.spacing(2)};
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;
  max-height: 300px; /* Pas deze waarde aan naar wens */

  /* Vaste rijhoogte en scrollbaarheid */
  & .MuiTableRow-root {
    height: 25px; /* Vaste hoogte voor rijen */
  }

  & .MuiTableCell-root {
    height: 25px; /* Vaste hoogte voor cellen */
    padding: 4px 8px; /* Verminder padding */
    font-size: 12px; /* Verminder font size */
    line-height: 25px; /* Stemmen op de vaste rijhoogte */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const SwitchWrapper = styled(Box)`
  display: flex;
  align-items: center;
  margin-left: ${theme.spacing(2)};
`;

const CustomTypography = styled(Typography)(({ theme }) => ({
  fontFamily: 'Lato',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '20px',
  textAlign: 'left',
  marginLeft: theme.spacing(1),
  color: theme.palette.grey[100]
}));

const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: '40px',
  height: '20px',
  padding: '0px',
  display: 'flex',
  alignItems: 'center',
  '& .MuiSwitch-switchBase': {
    padding: '3px',
    top: '50%',
    transform: 'translateY(-50%)',
    '&.Mui-checked': {
      transform: 'translate(20px, -50%)',
      color: '#FFFFFF',
      '& + .MuiSwitch-track': {
        backgroundColor: '#96C11F',
        opacity: 1
      }
    }
  },
  '& .MuiSwitch-track': {
    backgroundColor: '#ACB8BD',
    height: '20px',
    borderRadius: '10px',
    opacity: 1
  },
  '& .MuiSwitch-thumb': {
    width: '14px',
    height: '14px',
    backgroundColor: '#FFFFFF',
    position: 'relative',
    opacity: '1',
    boxShadow: 'none'
  }
}));
