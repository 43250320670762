import { Box, InputLabel, styled } from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DefaultAvatar from '../../../../assets/image/Users/DefaultAvatar.jpg';
import { DateInput } from '../../../../components/Input/DateInput';
import { CheckboxInput } from '../../../../components/Input/CheckboxInput';
import { TextInput } from '../../../../components/Input/TextInput';
import { dimensions } from '../../../../styles/dimensions';
import { BooleanValue } from '../../../../types/checkbox';
import { useUsersHook } from '../../store';
import { AvatarBox } from './AvatarBox';
import { GroupBar } from './GroupBar';
import { LocationDropBox } from '../../components/LocationDropBox';
import { BLANK_STRING } from './../../types';
import { theme } from '../../../../styles/theme';

// Styled component voor CheckboxInput om de label-tekst aan te passen
const StyledCheckboxInput = styled(CheckboxInput)`
  .MuiFormControlLabel-label {
    font-size: 7px; // Stel de gewenste font-size in
  }
`;

type Props = {
  isEdit: boolean;
  setLoading: (value: boolean) => void;
  initialTagNumber?: string; // Voeg deze prop toe
};

export const Body: React.FunctionComponent<Props> = ({ isEdit, setLoading, initialTagNumber }) => {
  const { t } = useTranslation();
  const [{ userInfo, freeTextLabel }] = useUsersHook();
  const displayUserInfo = isEdit && userInfo;

  const [endDate, setEndDate] = useState<string>(displayUserInfo ? userInfo.endDate : '');

  const handleEndDateChange = (value: string) => {
    if (value === '') {
      const newEndDate = moment().add(300, 'years').format('YYYY-MM-DD');
      setEndDate(newEndDate);
    } else {
      setEndDate(value);
    }
  };

  return (
    <UserInfoWrapper>
      <LeftColumn>
        <TextInput
          name="name"
          label={t('user.name')}
          required={true}
          placeholder={t('user.name')}
          defaultValue={displayUserInfo ? userInfo.name : BLANK_STRING}
        />

        {displayUserInfo && (
          <>
            <TextInput label={t('user.user_id')} disabled value={userInfo.id} />
            <TextInput label={t('user.client')} disabled value={userInfo.client} />
          </>
        )}

        <StyledCheckboxInput
          label={t('user.emergency_team')}
          name="emergency"
          labelWidth="30%"
          checkboxWidth="88%"
          defaultValue={displayUserInfo ? userInfo.emergency : BooleanValue.FALSE}
        />

        <StyledCheckboxInput
          label={t('user.special_rights')}
          name="special"
          labelWidth="30%"
          checkboxWidth="88%"
          defaultValue={displayUserInfo ? userInfo.special : BooleanValue.FALSE}
        />

        <AvatarBox name="avatar" defaultAvatar={displayUserInfo ? userInfo.avatar : DefaultAvatar} />

        <TextInput
          label={t('user.employee_number')}
          placeholder={t('user.employee_number')}
          name="employeeNr"
          defaultValue={displayUserInfo ? userInfo.employeeNr : BLANK_STRING}
        />

        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Label required={true} shrink={false}>
            {t('user.location')}
          </Label>
          <LocationDropBox
            width="72.5%"
            label={t('location.label')}
            name="locationId"
            defaultValue={displayUserInfo ? userInfo.locationName : BLANK_STRING}
            setLoading={setLoading}
          />
        </Box>

        <TextInput
          label={t('user.department')}
          placeholder={t('user.department')}
          name="department"
          defaultValue={displayUserInfo ? userInfo.departement : BLANK_STRING}
        />

        <TextInput
          label={t('user.phone')}
          placeholder={t('user.phone')}
          name="phone"
          defaultValue={displayUserInfo ? userInfo.phone : BLANK_STRING}
        />

        <TextInput
          label={t('user.email')}
          placeholder={t('user.email')}
          required={true}
          name="email"
          defaultValue={displayUserInfo ? userInfo.email : BLANK_STRING}
        />

        <TextInput
          label="Tag Number"
          placeholder="Tag Number"
          required={true}
          name="tagNumber"
          defaultValue={initialTagNumber || ''} // Gebruik initialTagNumber als deze aanwezig is
        />

        <TextInput
          label={t('user.pin')}
          placeholder={t('user.pin')}
          name="pin"
          defaultValue={displayUserInfo ? userInfo.pin : BLANK_STRING}
        />

        <StyledCheckboxInput
          label={t('user.tag_active')}
          name="tagActive"
          labelWidth="30%"
          checkboxWidth="88%"
          defaultValue={displayUserInfo ? userInfo.tagActive : BooleanValue.FALSE}
        />

        <TextInput
          label={t('user.tag_text')}
          placeholder={t('user.tag_text')}
          name="tagText"
          defaultValue={displayUserInfo ? userInfo.tagText : BLANK_STRING}
        />

        <DateInput
          label={t('user.start_date')}
          required={true}
          defaultValue={displayUserInfo ? userInfo.startDate : moment().toString()}
          name="startDate"
          inputWidth="80%"
          labelWidth="27.5%"
        />

        <DateInput
          label={t('user.end_date')}
          required={false}
          defaultValue={endDate}
          name="endDate"
          inputWidth="80%"
          labelWidth="27.5%"
          onChange={handleEndDateChange}
        />

        <TextInput
          label={freeTextLabel.freeForm1}
          placeholder={freeTextLabel.freeForm1}
          name="freeTextText1"
          defaultValue={displayUserInfo ? userInfo.freeTextText1 : BLANK_STRING}
        />

        <TextInput
          label={freeTextLabel.freeForm2}
          placeholder={freeTextLabel.freeForm2}
          name="freeTextText2"
          defaultValue={displayUserInfo ? userInfo.freeTextText2 : BLANK_STRING}
        />

        <TextInput
          label={freeTextLabel.comment}
          placeholder={freeTextLabel.comment}
          name="comment"
          defaultValue={displayUserInfo ? userInfo.comment : BLANK_STRING}
        />
      </LeftColumn>

      <GroupBar chosenGroupIds={displayUserInfo ? userInfo.groupNames.map(item => item.groupID) : []} />
    </UserInfoWrapper>
  );
};

const { base, baseSM, baseLG } = dimensions;
const { grey, error } = theme.palette;

const UserInfoWrapper = styled(Box)`
  display: flex;
  width: calc(100% - 48px);
  padding: 0 ${baseLG};
  column-gap: ${baseSM};
  height: calc(100% - 80px);
  flex-direction: row;
  ${props => props.theme.breakpoints.down(1400)} {
    flex-direction: column;
    row-gap: 120px;
  }
`;

const LeftColumn = styled(Box)`
  display: flex;
  flex: 1.2;
  flex-direction: column;
  justify-content: space-between;
  row-gap: ${baseSM};
  min-height: 500px;
  overflow-y: auto;
  margin: ${baseLG} 0 ${baseSM};
  padding: 0 ${base} ${base} 0;
  ${props => props.theme.breakpoints.down(1400)} {
    overflow-y: visible;
    margin-bottom: 380px;
  }
`;

const Label = styled(InputLabel)`
  color: ${grey[200]};
  .MuiInputLabel-asterisk {
    color: ${error.main} !important;
  }
`;
