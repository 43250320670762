import { Box, CircularProgress, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { OptionButton, OptionButtonType } from '../../../components/Button/OptionButton';
import { ResponsiveInfinityScroll } from '../../../components/ResponsiveInfinityScroll';
import SearchBar from '../../../components/SearchBar';
import { SideBarItemType } from '../../../types/route';
import { ButtonWrapper, Header } from '../components';
import { ListItem } from '../components/ListItem';
import { ListWrapper } from '../components/ListWrapper';
import { useUsersHook } from '../store';
import { BLANK_STRING, INITIAL_VALUE } from '../types';
import { VisitorRouteEndpoints } from './type';

type Props = {
  id: string;
};

const VisitorList: React.FunctionComponent<Props> = ({ id }) => {
  const { t } = useTranslation();

  const [{ visitorList, totalVisitors }, actions] = useUsersHook();
  const { getVisitorList, getVisitorsBySearch, setInitialVisitor } = actions;
  const navigate = useNavigate();

  const isAddNewMode = id && id === VisitorRouteEndpoints.ADD_NEW;
  const selectedVisitorId = !isAddNewMode ? id : BLANK_STRING;
  const hasMoreVisitors = visitorList && totalVisitors > visitorList.length;

  const [keyword, setKeyword] = useState<string>(BLANK_STRING);
  const [onSearchMode, setOnSearchMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSearch = async (value: string) => {
    setKeyword(value);
    setLoading(true);
    try {
      if (value.trim() === BLANK_STRING) {
        await getVisitorList();
      } else {
        await getVisitorsBySearch(value);
        setOnSearchMode(true);
      }
    } catch (error) {
      setInitialVisitor();
    } finally {
      setLoading(false);
    }
  };

  const handleScrollDown = async () => {
    const lastVisitorInList = visitorList[visitorList.length - 1];
    if (totalVisitors < visitorList.length || !lastVisitorInList) return;

    if (onSearchMode) {
      await getVisitorsBySearch(keyword, lastVisitorInList.id);
      return;
    }

    await getVisitorList(lastVisitorInList.id);
  };

  const handleChooseVisitor = async (visitorId: string) => {
    navigate(`/users/${SideBarItemType.Visitor}/${visitorId}`);
  };

  const goToAddNewVisitorPage = (): void => {
    navigate(`/users/${SideBarItemType.Visitor}/${VisitorRouteEndpoints.ADD_NEW}`);
  };

  const initVisitorList = async () => {
    setLoading(true);
    await getVisitorList();
    setLoading(false);
  };

  useEffect(() => {
    isEmpty(visitorList) && initVisitorList();
  }, []);

  useEffect(() => {
    if (visitorList.length > 0 && !id) {
      navigate(`/users/${SideBarItemType.Visitor}/${visitorList[0].visitorId}`);
    }
  }, [visitorList, id, navigate]);

  return (
    <ListWrapper>
      <Header>
        <Typography variant="subtitle4" color="grey.100">
          {t('visitor.list')} ({totalVisitors || INITIAL_VALUE})
        </Typography>
      </Header>
      {!isAddNewMode && (
        <ButtonWrapper>
          <OptionButton
            type={OptionButtonType.ADD_NEW}
            content={t('add_new')}
            isscaledown={true}
            onClick={goToAddNewVisitorPage}
          />
        </ButtonWrapper>
      )}

      <Box sx={{ width: '100%', maxWidth: 500, mb: 1 }}>
        <SearchBar
          onClear={getVisitorList}
          onSearch={handleSearch}
          placeHolder={t('visitor.search_bar_placeholder')}
          keyword={keyword}
          onKeywordChanged={setKeyword}
        />
      </Box>

      {!loading && isEmpty(visitorList) ? (
        <Typography variant="subtitle1" color="grey.200">
          {t('search_no_result')}
        </Typography>
      ) : (
        <ResponsiveInfinityScroll
          height="40vh"
          dataLength={visitorList ? visitorList.length : 0}
          next={handleScrollDown}
          hasMore={loading || hasMoreVisitors}
          loader={
            <Box display="flex" justifyContent="center">
              <CircularProgress color="primary" size={40} />
            </Box>
          }>
          {visitorList &&
            visitorList.map(visitor => (
              <ListItem
                key={visitor.visitorId}
                className={selectedVisitorId === visitor.visitorId ? 'active' : BLANK_STRING}
                onClick={() => handleChooseVisitor(visitor.visitorId)}
                variant="body1">
                {visitor.name}
              </ListItem>
            ))}
        </ResponsiveInfinityScroll>
      )}
    </ListWrapper>
  );
};

export default VisitorList;
