import React, { useState } from 'react';
import { ReactComponent as Employee } from '../../../../assets/image/user.svg';
import { ReactComponent as Visitor } from '../../../../assets/image/visitor.svg';
import { ReactComponent as Unknown } from '../../../../assets/image/unknown.svg';
import { ReactComponent as System } from '../../../../assets/image/system.svg';
import { theme } from '../../../../styles/theme';
import { dimensions } from '../../../../styles/dimensions';
import {
  TableHead,
  styled,
  TableContainer,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  Table,
  Box,
  Fade
} from '@mui/material';
import BodyTableCell from './BodyTableCell';
import { SecondaryButton } from '../../../../components/Button';
import { useTranslation } from 'react-i18next';
import { LogLevel, UserIcon } from '../../../../models';
import { CellAlignment } from '../../../../types/cell';
import { useReportStates } from '../../store';
import { ReportLogColumn, RowPosition } from '../../types';

// Configuratievariabele om highlights in of uit te schakelen
const HIGHLIGHTS_ENABLED = false; // Zet dit op true om highlights in te schakelen, of false om ze uit te schakelen

const getRowLevelColor = (logLevel: LogLevel, position?: RowPosition) => {
  const { error, warning, action, common } = theme.palette;
  switch (logLevel) {
    case LogLevel.NORMAL:
      return position === RowPosition.ODD ? action.hover : common.white;
    case LogLevel.MEDIUM:
      return warning.light;
    case LogLevel.HIGH:
    default:
      return error.light;
  }
};

const renderUserTypeIcon = (type: UserIcon) => {
  switch (type) {
    case UserIcon.EMPLOYEE:
      return <Employee />;
    case UserIcon.VISITOR:
      return <Visitor />;
    case UserIcon.SYSTEM:
      return <System />;
    case UserIcon.UNKNOWN:
    default:
      return <Unknown />;
  }
};

const getRouteDomainByUserIcon = (userIcon: UserIcon, userId: string | null) => {
  if (!userId) return;
  switch (userIcon) {
    case UserIcon.EMPLOYEE:
    case UserIcon.VISITOR:
      return `/users/${userId}`;
    default:
      return;
  }
};

const parseTimeToDate = (timeStr: string): Date => {
  const today = new Date();
  const [hours, minutes, seconds] = timeStr.split(':').map(Number);
  return new Date(today.getFullYear(), today.getMonth(), today.getDate(), hours, minutes, seconds);
};

const GridTable: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { logs } = useReportStates();
  const [highlightsEnabled, setHighlightsEnabled] = useState(HIGHLIGHTS_ENABLED); // Zet hier in of uit

  // Sort logs by time (newest first)
  const sortedLogs = React.useMemo(() => {
    return [...logs].sort((a, b) => {
      const dateA = parseTimeToDate(a.time);
      const dateB = parseTimeToDate(b.time);

      if (isNaN(dateA.getTime()) || isNaN(dateB.getTime())) {
        console.error('Invalid date:', a.time, b.time);
      }

      return dateB.getTime() - dateA.getTime(); // Nieuwste eerst
    });
  }, [logs]);

  return (
    <Transition in={true}>
      <Wrapper>
        <Header>
          <Typography variant="subtitle1" color="grey.100">
            {t('report.content')}
          </Typography>
          <SecondaryButton content={t('report.export')} height={buttonHeight} />
        </Header>
        <Container>
          <Table stickyHeader>
            <TableHeader>
              <TableRow>
                {Object.values(ReportLogColumn).map((item, index) => (
                  <TableCell key={index}>
                    <Typography variant="subtitle2" color="grey.100">
                      {item}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHeader>
            <Body>
              {sortedLogs.map(log => (
                <Row key={log.logID} status={log.logLevel} highlightsEnabled={highlightsEnabled}>
                  <BodyTableCell value={renderUserTypeIcon(log.userIcon)} align={CellAlignment.CENTER} />
                  <BodyTableCell value={log.name} link={getRouteDomainByUserIcon(log.userIcon, log.userId)} />
                  <BodyTableCell value={log.time} />
                  <BodyTableCell value={log.message} />
                  <BodyTableCell value={log.doorName} />
                  <BodyTableCell value={log.groupName} />
                  <BodyTableCell value={log.doorLocation} />
                  <BodyTableCell value={log.departement} />
                  <BodyTableCell value={log.room} />
                  <BodyTableCell value={log.carPlate} />
                </Row>
              ))}
            </Body>
          </Table>
        </Container>
      </Wrapper>
    </Transition>
  );
};

export default GridTable;

const { common, grey } = theme.palette;
const { inputHeight, buttonHeight } = dimensions;

type TableRowProps = {
  status: LogLevel | undefined;
  highlightsEnabled: boolean;
};

const Transition = styled(Fade)`
  transition-delay: 200ms;
`;

const Wrapper = styled(Box)`
  display: flex;
  height: calc(100% - 170px);
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-self: center;
`;

const Header = styled(Box)`
  height: 56px;
  width: 98%;
  display: flex;
  align-self: center;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const TableHeader = styled(TableHead)`
  height: ${inputHeight};
  background: ${common.white};
  border: 1px solid ${grey[400]};
  font-size: 16px;
  font-weight: 700;
`;

const Container = styled(TableContainer)`
  height: calc(100% - 47px);
  overflow: auto;
  border-top: 1px solid ${grey[400]};
`;

const Row = styled(TableRow)<TableRowProps>(({ status, highlightsEnabled }) => ({
  position: 'relative',
  zIndex: '0',
  backgroundColor: highlightsEnabled ? (status ? getRowLevelColor(status) : 'transparent') : 'transparent',
  '&:nth-of-type(odd)': {
    backgroundColor: highlightsEnabled
      ? status
        ? getRowLevelColor(status, RowPosition.ODD)
        : 'transparent'
      : 'transparent'
  },
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));

const Body = styled(TableBody)`
  position: relative;
  overflow: auto;
`;
