import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Divider, styled, Typography, Link } from '@mui/material';
import { OptionButton, OptionButtonType } from '../../../../components/Button/OptionButton';
import { dimensions } from '../../../../styles/dimensions';
import { theme } from '../../../../styles/theme';
import { useUsersStates } from '../../store';
import { BLANK_STRING } from '../../types';
import { VisitorRouteEndpoints } from '../type';
import { $delete } from '../../../../utils/http';
import { showErrorToast, showSuccessToast } from '../../../../components/Toast/actions';
import { Popup } from '../../../../components/Popup';

// Header Component
export const Header: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [showPrompt, setShowDeletePopup] = useState(false); // State voor de verwijder popup

  const { visitorInfo } = useUsersStates();

  const handleEdit = () => {
    navigate(`${VisitorRouteEndpoints.EDIT}`);
  };

  const handleClickDeleteButton = () => {
    setShowDeletePopup(true); // Toon de delete popup
  };

  const cancelDelete = () => {
    setShowDeletePopup(false); // Sluit de popup zonder actie
  };

  const confirmDelete = async () => {
    try {
      const visitorId = visitor.id;
      console.log('Attempting to delete user with ID:', visitorId, ' (Naam : ', visitor.name, ' )'); // Log de poging om de gebruiker te verwijderen
      const response = await $delete(`/user/${visitor.id}`);

      console.log('Response status:', response.status); // Log de status van de response
      if (response.status === 200) {
        showSuccessToast({
          title: t('visitor.deletenot.success.title'),
          subtitle: t('visitor.deletenot.success.subtitle')
        });
        setShowDeletePopup(false);
        navigate('/users/visitor'); // Navigeer naar de gebruikerslijst of een relevante pagina
        window.location.reload();
      } else {
        showSuccessToast({
          title: t('visitor.deletenot.success.title'),
          subtitle: t('visitor.deletenot.success.subtitle')
        });
        setShowDeletePopup(false);
        navigate('/users/visitor'); // Navigeer naar de gebruikerslijst of een relevante pagina
        setTimeout(() => {
          window.location.reload();
        }, 500); // Wacht 0.5 seconden voordat de pagina herladen wordt
      }
    } catch (error) {
      console.error('Error deleting user:', error); // Log de fout die gevangen is in de catch
      showErrorToast({
        title: t('visitor.deletenot.error.title'),
        subtitle: t('visitor.deletenot.error.subtitle') // Correcte key voor error subtitle
      });
    }
  };

  if (!visitorInfo) return <></>;

  const { name, phone, email, visitor: contractPerson } = visitorInfo;

  // Definieer de visitor object met alle benodigde info
  const visitor = {
    id: id || BLANK_STRING,
    name,
    phone,
    email
  };

  // Haal contractPersonName en contractPersonPhone op zoals in BelongVisitors
  const visitorPhone = visitor.phone ? `+${visitor.phone}` : BLANK_STRING;
  const contractPersonName = contractPerson?.contactName || BLANK_STRING;
  const contractPersonPhone = contractPerson?.phone ? `+${contractPerson.phone}` : BLANK_STRING;

  return (
    <Wrapper>
      <Popup
        title={t('Delete Visitor')}
        styleChoice="style2"
        open={showPrompt}
        onClose={cancelDelete}
        closeactiontext={t('Cancel')}
        action={{
          name: t('Delete'),
          onAction: confirmDelete
        }}
      />
      <InfoWrapper flexDirection="column" rowGap={`${baseXS}`}>
        <Typography variant="h3" color="grey.100">
          {visitor.name}
        </Typography>
        <Box display="flex" flexDirection="row" rowGap={`${baseXS}`}>
          <Box display="flex" flexDirection="column" rowGap={`${baseXS}`}>
            <InfoWrapper flexDirection="row">
              <TextWrapper variant="body2">{t('visitor.phone_number')}</TextWrapper>
              <Typography variant="subtitle4" color="grey.200">
                {visitorPhone}
              </Typography>
            </InfoWrapper>

            <InfoWrapper flexDirection="row">
              <TextWrapper variant="body2">{t('visitor.email')}</TextWrapper>
              <Typography variant="subtitle4" color="grey.200">
                {visitor.email}
              </Typography>
            </InfoWrapper>
          </Box>

          <VerticalLine orientation="vertical" />

          <UserImageWrapper component="img" src={contractPerson?.contactAvatar || BLANK_STRING} />

          <Box display="flex" flexDirection="column" rowGap={`${baseXS}`} paddingLeft="20px">
            <InfoWrapper flexDirection="row">
              <TextWrapper variant="body2">{t('visitor.contract_name')}</TextWrapper>
              <Link
                href={`/users/user/${contractPerson?.contactId}`}
                target="_blank"
                rel="noopener noreferrer"
                underline="none">
                <ContractPersonNameWrapper variant="subtitle4" color="grey.100">
                  {contractPersonName}
                </ContractPersonNameWrapper>
              </Link>
            </InfoWrapper>

            <InfoWrapper flexDirection="row">
              <TextWrapper variant="body2">{t('visitor.department')}</TextWrapper>
              <Typography variant="subtitle4" color="grey.200">
                {contractPerson ? contractPerson.departement : BLANK_STRING}
              </Typography>
            </InfoWrapper>

            <InfoWrapper flexDirection="row">
              <TextWrapper variant="body2">{t('visitor.phone_number')}</TextWrapper>
              <Typography variant="subtitle4" color="grey.200">
                {contractPersonPhone}
              </Typography>
            </InfoWrapper>
          </Box>
        </Box>
      </InfoWrapper>

      <ButtonWrapper>
        <div style={{ marginRight: '40px' }}>
          <OptionButton
            type={OptionButtonType.DELETE}
            content={t('user.delete')}
            onClick={handleClickDeleteButton}
            isscaledown={false}
          />
        </div>
        <OptionButton type={OptionButtonType.EDIT} content={t('edit')} onClick={handleEdit} isscaledown={false} />
      </ButtonWrapper>
    </Wrapper>
  );
};

const { base, baseSM, baseXS, baseMD, baseLG, buttonHeight } = dimensions;
const { grey } = theme.palette;

const Wrapper = styled(Box)`
  padding: ${baseMD} ${baseLG};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const UserImageWrapper = styled(Box)(({ src }: { src: string | undefined }) => ({
  width: '64px',
  height: '64px',
  border: `1px solid ${grey[400]}`,
  borderRadius: `${baseXS}`,
  boxShadow: `${theme.shadows[2]}`,
  src: src,
  marginRight: `${baseSM}`,
  flexShrink: 0
}));

const InfoWrapper = styled(Box)`
  display: flex;
  min-width: 200px;
  column-gap: 0;
`;

const ButtonWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  flex-flow: row wrap;
  column-gap: ${base};
  row-gap: ${base};
  height: ${buttonHeight};
`;

const TextWrapper = styled(Typography)`
  width: 109px;
  color: ${grey[200]};
`;

const ContractPersonNameWrapper = styled(TextWrapper)`
  border-bottom: 1px solid ${grey[100]};
  width: fit-content;
`;

const VerticalLine = styled(Divider)`
  background-color: ${grey[400]};
  height: 64px;
  margin: 0 ${baseMD};
`;
