import { camelCaseKeysObj, lowercaseKeysObj } from '../../../../utils/object';
import { isBase64String } from '../../../../utils/string';
import { BLANK_STRING } from '../../types';
import { ImportUserInfo } from '../type';
import {
  DEFAULT_EMERGENCY,
  DEFAULT_END_DATE,
  DEFAULT_END_TIME,
  DEFAULT_SPECIAL,
  DEFAULT_START_DATE,
  DEFAULT_START_TIME,
  DEFAULT_TAG_ACTIVE
} from '../util';
import { isValidUserProperties } from '../validator';

export const importUserConverter = (data: { [key: string]: string }): ImportUserInfo => {
  // Convert to camelCase to remove space or dash between words of key
  const camelCaseKeysData = camelCaseKeysObj<{ [key: string]: string }>(data);

  // Convert to lowercase to match with ValidUserProperties type keys
  const lowercaseKeysData = lowercaseKeysObj<{ [key: string]: string }>(camelCaseKeysData);

  if (!isValidUserProperties(lowercaseKeysData)) {
    throw new Error('user.import_user_toast.failed.subtitle.missing_important_fields');
  }

  const {
    groupnames,
    startdate,
    name,
    pin,
    departement,
    location,
    comment,
    phone,
    employeenr,
    tagtext,
    email,
    tagnumber,
    tagactive,
    freetexttext1,
    freetexttext2,
    emergency,
    special,
    avatar,
    enddate,
    roomnumber,
    client,
    starttime,
    endtime
  } = lowercaseKeysData;

  // backend required 1, 0 present for yes, no values
  const bitValues = ['1', '0'];

  // avoid spread because it will includes properties that doesn't defined for create user
  return {
    name,
    pin: pin || BLANK_STRING,
    departement: departement || BLANK_STRING,
    locationName: location || BLANK_STRING,
    comment: comment || BLANK_STRING,
    phone: phone || BLANK_STRING,
    employeeNr: employeenr || BLANK_STRING,
    tagText: tagtext || BLANK_STRING,
    email: email || BLANK_STRING,
    tagNumber: tagnumber || BLANK_STRING,
    tagActive: bitValues.includes(tagactive) ? tagactive : DEFAULT_TAG_ACTIVE,
    freeTextText1: freetexttext1 || BLANK_STRING,
    freeTextText2: freetexttext2 || BLANK_STRING,
    plate: BLANK_STRING,
    vehicle: BLANK_STRING,
    emergency: bitValues.includes(emergency) ? emergency : DEFAULT_EMERGENCY,
    special: bitValues.includes(special) ? special : DEFAULT_SPECIAL,
    roomNumber: roomnumber || BLANK_STRING,
    client: client || BLANK_STRING,
    avatar: isBase64String(avatar) ? avatar : BLANK_STRING,
    startDate: startdate || DEFAULT_START_DATE,
    endDate: enddate || DEFAULT_END_DATE,
    startTime: starttime || DEFAULT_START_TIME,
    endTime: endtime || DEFAULT_END_TIME,
    groupNames: groupnames ? groupnames.split(',') : []
  };
};
