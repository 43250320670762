import React, { useState, useEffect } from 'react';
import { Box, Typography, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { styled } from '@mui/system';
import OpenInNew from '@mui/icons-material/OpenInNew';
import WarningAmber from '@mui/icons-material/WarningAmber';
import { $get } from '../../utils/http';

interface SystemAlertsBlockProps {
  locationIds?: number[];
}

const SystemAlertsBlock: React.FC<SystemAlertsBlockProps> = ({ locationIds }) => {
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [dialogType, setDialogType] = useState<string | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        let url = '/dashboard/systemAlerts';
        if (locationIds && locationIds.length > 0) {
          url += `?location=${locationIds.join(',')}`;
        }

        const response = await $get(url);

        // Controleer of response bestaat en valide is
        if (response && typeof response === 'object' && !Array.isArray(response)) {
          setData(response);
          setError(null);
        } else {
          throw new Error('Invalid response data');
        }
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to load alerts');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [locationIds]);

  const handleDialogOpen = (type: string) => {
    setDialogType(type);
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setDialogType(null);
  };

  if (loading) {
    return <BlockContainer>Loading...</BlockContainer>;
  }

  if (error) {
    return <BlockContainer>Error: {error}</BlockContainer>;
  }

  // Controleer of de data niet leeg is of ongeldig
  const isDataInvalid = !data || Object.values(data).some(value => value !== 0 && value !== 1);

  const getAlertColor = (value: number) => {
    if (value === 0) return '#F2FBD7'; // Groen
    if (value === 1) return '#FFE1D8'; // Rood
    return '#FFFF00'; // Oranje
  };

  const renderAlertBox = (label: string, value: number, alertMessage: string) => (
    <AlertBox
      key={label}
      type={label}
      defaultColor={isDataInvalid ? '#F2FBD7' : getAlertColor(value)} // Oranje als data ongeldig
      defaultBorderColor={isDataInvalid ? '#96C11F' : getAlertColor(value)}
      activeColor={isDataInvalid ? '#FFFF00' : getAlertColor(value)}
      activeBorderColor={isDataInvalid ? '#FFFF00' : getAlertColor(value)}
      isActive={value > 0}
      onClick={() => value > 0 && handleDialogOpen(alertMessage)}>
      {label}
    </AlertBox>
  );

  return (
    <BlockContainer>
      <TitleContainer>
        <WarningAmberIcon />
        <Title>SYSTEM ALERTS</Title>
      </TitleContainer>
      <AlertsContainer>
        {renderAlertBox('Doors', data.doors || 0, data.alert_doors || 'No errors')}
        {renderAlertBox('Processors', data.processors || 0, data.alert_processors || 'No errors')}
        {renderAlertBox('Readers', data.readers || 0, data.alert_readers || 'No errors')}
        {renderAlertBox('Security Alarms', data.security_alarms || 0, data.alert_security_alarms || 'No errors')}
      </AlertsContainer>
      <Dialog open={isDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>{`Error ${dialogType}`}</DialogTitle>
        <DialogContent>
          <Typography>{`ERROR MESSAGE FROM API`}</Typography> {/* Vervang met de specifieke foutmelding */}
        </DialogContent>
      </Dialog>
    </BlockContainer>
  );
};

export default SystemAlertsBlock;

const BlockContainer = styled(Box)`
  width: 448px;
  height: 75px;
  padding: 16px 20px;
  gap: 8px;
  background: #e5e9eb;
  border: 1px solid #d8dfe0;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const TitleContainer = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const Title = styled(Typography)`
  font-family: Lato;
  font-size: 14px;
  font-weight: 800;
  line-height: 16.8px;
  letter-spacing: 0.015em;
  text-align: left;
`;

const WarningAmberIcon = styled(WarningAmber)`
  color: #000000;
  margin-right: 8px;
`;

const AlertsContainer = styled(Box)`
  display: flex;
  gap: 8px;
  justify-content: space-between;
  width: 100%;
`;

interface AlertBoxProps {
  children: React.ReactNode;
  type: string;
  defaultColor: string;
  defaultBorderColor: string;
  activeColor: string;
  activeBorderColor: string;
  isActive: boolean;
  onClick: () => void;
}

const AlertBox: React.FC<AlertBoxProps> = ({
  children,
  type,
  defaultColor,
  defaultBorderColor,
  activeColor,
  activeBorderColor,
  isActive,
  onClick
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const handleIconClick = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <StyledAlertBox
        onClick={onClick}
        sx={{
          backgroundColor: isActive ? activeColor : defaultColor,
          borderColor: isActive ? activeBorderColor : defaultBorderColor,
          textAlign: isActive ? 'left' : 'center'
        }}
        isActive={isActive}>
        {children}
        {isActive && <OpenInNew sx={{ color: '#698223', marginLeft: 'auto' }} onClick={handleIconClick} />}
      </StyledAlertBox>
      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>{`Error ${type}`}</DialogTitle>
        <DialogContent>
          <Typography>{`ERROR MESSAGE FROM API`}</Typography> {/* Vervang met de specifieke foutmelding */}
        </DialogContent>
      </Dialog>
    </>
  );
};

const StyledAlertBox = styled(Box)<{ isActive: boolean }>`
  width: 175px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: ${({ isActive }) => (isActive ? 'flex-start' : 'center')};
  border-radius: 8px;
  font-family: Lato;
  font-size: 13px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #000000;
  border: 1px solid;
  cursor: pointer;
  padding: 0 6px;
`;
