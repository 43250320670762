// src/data/demo.ts

import { EmergencyInfo } from '../../models/emergency';
import { StatusEmergencyTeam } from './type'; // Pas het pad aan indien nodig

export const demoEmergencyMembers: EmergencyInfo[] = [
  {
    id: 'id-1',
    name: 'Member A',
    lastScannedDoor: 'Door 1',
    location: 'Location 1',
    status: StatusEmergencyTeam.ON,
    locationId: 'loc-1'
  },
  {
    id: 'id-2',
    name: 'Member B',
    lastScannedDoor: 'Door 2',
    location: 'Location 2',
    status: StatusEmergencyTeam.OFF,
    locationId: 'loc-2'
  },
  {
    id: 'id-3',
    name: 'Member C',
    lastScannedDoor: 'Door 3',
    location: 'Location 3',
    status: StatusEmergencyTeam.ON,
    locationId: 'loc-3'
  },
  {
    id: 'id-4',
    name: 'Member D',
    lastScannedDoor: 'Door 4',
    location: 'Location 4',
    status: StatusEmergencyTeam.OFF,
    locationId: 'loc-4'
  },
  {
    id: 'id-5',
    name: 'Member E',
    lastScannedDoor: 'Door 5',
    location: 'Location 5',
    status: StatusEmergencyTeam.ON,
    locationId: 'loc-5'
  },
  {
    id: 'id-6',
    name: 'Member F',
    lastScannedDoor: 'Door 6',
    location: 'Location 6',
    status: StatusEmergencyTeam.OFF,
    locationId: 'loc-6'
  },
  {
    id: 'id-7',
    name: 'Member G',
    lastScannedDoor: 'Door 7',
    location: 'Location 7',
    status: StatusEmergencyTeam.ON,
    locationId: 'loc-7'
  },
  {
    id: 'id-8',
    name: 'Member H',
    lastScannedDoor: 'Door 8',
    location: 'Location 8',
    status: StatusEmergencyTeam.OFF,
    locationId: 'loc-8'
  },
  {
    id: 'id-9',
    name: 'Member I',
    lastScannedDoor: 'Door 9',
    location: 'Location 9',
    status: StatusEmergencyTeam.ON,
    locationId: 'loc-9'
  },
  {
    id: 'id-10',
    name: 'Member J',
    lastScannedDoor: 'Door 10',
    location: 'Location 10',
    status: StatusEmergencyTeam.OFF,
    locationId: 'loc-10'
  }
];
