export const enum StatusEmergencyTeam {
  ON = 'ON',
  OFF = 'OFF'
}

export enum EmergencyKeys {
  ID = 'id',
  NAME = 'name',
  LOCATION = 'location',
  STATUS = 'status',
  LAST = 'last scanned door'
}

export enum TabIndex {
  LOCATION = 0,
  SOFTWARE_UPDATES = 1
}

export const DEFAULT_ID = -1;
export const ALL = 'All';
